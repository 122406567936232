// src/pages/RightSidebar.js
import React, { useMemo } from 'react';
import BuyCardPostAuth from '../InfoComponents/BuyCardPostAuth';
import MiniSymbolOverviewWidget from '../InfoComponents/MiniSymbolOverviewWidget';

const RightSidebar = ({
  loading,
  error,
  company,
  stats,
  marketPrice,
  shares,
  setShares,
  disableInteractions,
  accounts,
  selectedAccount,
  handleSelectAccount,
  handleBuyStock,
  orderConfirmed,
  orderData,
  setOrderConfirmed,
  setOrderData,
  setCompanyName,
  setSelectedAccount,
}) => {
  // Safely handle the symbol string
  const symbolString = company?.trading_view_symbol || 'NYSE:SLV';

//   // Memoize widget props so it doesn't create a new object on every render
//   const widgetProps = useMemo(() => {
//     return {
//       symbol: symbolString,       // The symbol for the chart, e.g. "TSXV:DV"
//       width: '100%',             // Adjust width/height as needed
//       height: 220,
//       locale: 'en',
//       colorTheme: 'light',
//       // Additional config (see TradingView docs)
//       // e.g.: isTransparent: false,
//       //       autosize: false,
//       //       dateRange: '1D',
//     };
//   }, [symbolString]);

  return (
    <aside className="flex-col hidden p-4 space-y-4 bg-white lg:flex w-80">
      {/* Loading or Error */}
      {loading && <div>Loading company info...</div>}
      {error && <div className="text-red-500">{error}</div>}

      {/* If we have company data, show the chart and stats */}
      {company && (
        <>
          {/* TradingView Mini Chart */}
          <div className="flex-none">
            <h2 className="mb-2 text-lg font-bold">
              {company.name}
            </h2>
            <div className="overflow-hidden rounded-lg">
            {/* The TradingView Mini Symbol Overview */}
            <MiniSymbolOverviewWidget />
            </div>
          </div>

          {/* Key Stats */}
          <div className="flex-none">
            <h3 className="mb-1 text-sm font-semibold">Key Stats</h3>
            <div className="space-y-1 text-sm">
              {stats.map((stat, idx) => (
                <p key={idx}>
                  <span className="font-bold">{stat.title}:</span> {stat.value}
                </p>
              ))}
            </div>
          </div>

          {/* Buy Card */}
          <div className="flex-1 overflow-auto">
            <BuyCardPostAuth
              company={company}
              shares={shares}
              handleSharesChange={(e) => setShares(e.target.value)}
              marketPrice={marketPrice}
              estimatedCost={(Number(shares) * Number(marketPrice)).toFixed(2)}
              disableInteractions={disableInteractions}
              accounts={accounts}
              selectedAccount={selectedAccount}
              handleSelectAccount={handleSelectAccount}
              handleBuyStock={handleBuyStock}
              orderConfirmed={orderConfirmed}
              orderData={orderData}
              setOrderConfirmed={setOrderConfirmed}
              setOrderData={setOrderData}
              setShares={setShares}
              setSelectedAccount={setSelectedAccount}
              setCompanyName={setCompanyName}
              setMarketPrice={() => {
                /* override if needed */
              }}
            />
          </div>
        </>
      )}
    </aside>
  );
};

export default RightSidebar;
