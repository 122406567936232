// src/hooks/useSnaptradeAccounts.js

import { useState, useEffect } from 'react';

export default function useSnaptradeAccounts(userId) {
  const [accounts, setAccounts] = useState([]);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [accountError, setAccountError] = useState(null);

  useEffect(() => {
    if (!userId) {
      setLoadingAccounts(false);
      return;
    }

    const fetchAccounts = async () => {
      try {
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        // This endpoint fetches user’s SnapTrade accounts (like /accounts?userId= etc.)
        const res = await fetch(`${backendUrl}/accounts`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Supabase-User-ID': userId,
          },
        });
        if (!res.ok) {
          throw new Error('Failed to fetch accounts');
        }
        const data = await res.json();
        setAccounts(data);
      } catch (err) {
        console.error('Error fetching snaptrade accounts:', err);
        setAccountError(err.message);
      } finally {
        setLoadingAccounts(false);
      }
    };

    fetchAccounts();
  }, [userId]);

  return {
    accounts,
    loadingAccounts,
    accountError,
  };
}
