// src/hooks/useCompanyInfo.js

import { useState, useEffect } from 'react';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';
import { supabase } from '../supabaseClient';  // (Adjust the path as needed)


export default function useCompanyInfo() {
  const [company, setCompany] = useState(null);
  const [stats, setStats] = useState([]);
  const [marketPrice, setMarketPrice] = useState('0.00');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const companyId = parseInt(process.env.REACT_APP_COMPANY_ID, 10);
  const ALPHA_VANTAGE_API_KEY = process.env.REACT_APP_ALPHA_VANTAGE_API_KEY;

  // Fetch row from 'companies' table
  useEffect(() => {
    const fetchCompanyData = async () => {
      if (!companyId) {
        setError('Company ID is missing in .env');
        setLoading(false);
        return;
      }
      try {
        const { data: companyData, error: fetchError } = await supabase
          .from('companies')
          .select('*')
          .eq('id', companyId)
          .maybeSingle();

        if (fetchError) {
          setError('Error fetching company data');
        } else {
          setCompany(companyData);
        }
      } catch (err) {
        console.error('Error fetching company data:', err);
        setError('Failed to fetch company data');
      } finally {
        setLoading(false);
      }
    };
    fetchCompanyData();
  }, [companyId, supabase]);

  // Once we have `company`, fetch stats from Alpha Vantage
  useEffect(() => {
    const fetchStats = async () => {
      if (!company || !company.ticker_symbol_ca) return;
      if (!ALPHA_VANTAGE_API_KEY) {
        setError('Alpha Vantage API key missing');
        return;
      }
      try {
        const response = await axios.get('https://www.alphavantage.co/query', {
          params: {
            function: 'GLOBAL_QUOTE',
            symbol: company.ticker_symbol_ca,
            apikey: ALPHA_VANTAGE_API_KEY,
          },
        });
        const data = response.data?.['Global Quote'];
        if (data && data['05. price']) {
          setMarketPrice(parseFloat(data['05. price']).toFixed(2));
          const fetchedStats = [
            {
              title: 'Open',
              value: data['02. open']
                ? `$${parseFloat(data['02. open']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'High',
              value: data['03. high']
                ? `$${parseFloat(data['03. high']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Low',
              value: data['04. low']
                ? `$${parseFloat(data['04. low']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Prev Close',
              value: data['08. previous close']
                ? `$${parseFloat(data['08. previous close']).toFixed(2)}`
                : 'N/A',
            },
            {
              title: 'Change',
              value: data['09. change']
                ? parseFloat(data['09. change']).toFixed(2)
                : 'N/A',
            },
            {
              title: 'Percent Change',
              value: data['10. change percent'] || 'N/A',
            },
          ];
          setStats(fetchedStats);
        } else {
          console.error('No data from Alpha Vantage');
          setError('No data for this symbol');
        }
      } catch (err) {
        console.error('Error fetching key statistics:', err);
        setError('Failed to fetch key statistics');
      }
    };
    fetchStats();
  }, [company, ALPHA_VANTAGE_API_KEY]);

  return {
    company,
    stats,
    marketPrice,
    error,
    loading,
  };
}
