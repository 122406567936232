// src/routes/PosthogListener.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import posthog from 'posthog-js';

const PosthogListener = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on location change
    posthog.capture('$pageview', { path: location.pathname });
  }, [location]);

  return children;
};

export default PosthogListener;
