// src/services/snaptradeService.js

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// ─────────────────────────────────────────────────────────────────────────────
// 1) Check if user is linked
// ─────────────────────────────────────────────────────────────────────────────
export async function isUserLinked(supabaseUserId) {
  try {
    const response = await fetch(`${BACKEND_URL}/is-linked`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Supabase-User-ID': supabaseUserId,
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data.isLinked;
    }
  } catch (err) {
    console.error('Error checking link status:', err);
  }
  return false;
}

// ─────────────────────────────────────────────────────────────────────────────
// 2) Fetch SnapTrade login link
// ─────────────────────────────────────────────────────────────────────────────
export async function fetchLoginLink(supabaseUserId, brokerage) {
  try {
    const response = await fetch(`${BACKEND_URL}/link-snaptrade`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Supabase-User-ID': supabaseUserId,
      },
      body: JSON.stringify({
        snaptradeUserId: `snaptrade-${supabaseUserId}`,
        brokerage,
      }),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch SnapTrade login link');
    }
    const data = await response.json();
    return data.redirectURI;
  } catch (err) {
    console.error('Error fetching login link:', err);
    throw err;
  }
}

// ─────────────────────────────────────────────────────────────────────────────
// 3) Enter the user in the giveaway
// ─────────────────────────────────────────────────────────────────────────────
export async function enterGiveaway(supabaseUserId) {
  try {
    const response = await fetch(`${BACKEND_URL}/enter-giveaway`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Supabase-User-ID': supabaseUserId,
      },
    });
    if (!response.ok) {
      console.error('Failed to enter giveaway');
    }
  } catch (err) {
    console.error('Error entering giveaway:', err);
  }
}

// ─────────────────────────────────────────────────────────────────────────────
// 4) Fetch SnapTrade Accounts (optional; if you don't handle in a hook)
// ─────────────────────────────────────────────────────────────────────────────
export async function fetchSnaptradeAccounts(supabaseUserId) {
  try {
    const response = await fetch(`${BACKEND_URL}/accounts`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Supabase-User-ID': supabaseUserId,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch accounts');
    }
    return await response.json();
  } catch (err) {
    console.error('Error fetching snaptrade accounts:', err);
    throw err;
  }
}

// ─────────────────────────────────────────────────────────────────────────────
// 5) Place an order (Market Buy) via SnapTrade
// ─────────────────────────────────────────────────────────────────────────────
// userId:      Supabase user ID
// accountId:   The account to trade in
// symbol:      Ticker/universal symbol
// quantity:    Number of shares
// marketPrice: For your record or the endpoint (some APIs require it)
export async function placeOrder({ userId, accountId, symbol, quantity, marketPrice }) {
  try {
    const response = await fetch(`${BACKEND_URL}/buy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Supabase-User-ID': userId,
      },
      body: JSON.stringify({
        userId,
        // userSecret: 'If needed from your DB', 
        accountId, 
        universalSymbolId: symbol,
        quantity,
        orderType: 'Market',
        limitPrice: null, // or pass a value if you do limit orders
        // Additional fields if your backend expects them
      }),
    });

    if (!response.ok) {
      throw new Error('Error placing order');
    }

    // The response might contain details like orderId, status, etc.
    const data = await response.json();
    return data;
  } catch (err) {
    console.error('Error in placeOrder:', err);
    throw err;
  }
}
