// src/components/Auth/AuthModal.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { supabase } from '../../services/supabaseClient';
import {
  isUserLinked,
  fetchLoginLink,
  enterGiveaway,
} from '../../services/snaptradeService';

const AuthModal = ({
  isOpen,
  onClose,
  onLinked,    // callback for successful linking
  onSkipLink,  // callback if user decides to skip linking
}) => {
  const [authStep, setAuthStep] = useState('selectBrokerage'); 
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [brokerage, setBrokerage] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [loginLink, setLoginLink] = useState(null);
  const [otherBrokerageName, setOtherBrokerageName] = useState('');
  const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(false);

  // Brokerage options
  const brokerageOptions = [
    { value: 'ETRADE', label: 'E*TRADE' },
    { value: 'ROBINHOOD', label: 'Robinhood' },
    { value: 'SCHWAB', label: 'Schwab' },
    { value: 'TRADESTATION', label: 'TradeStation' },
    { value: 'VANGUARD', label: 'Vanguard US' },
    { value: 'WEALTHSIMPLETRADE', label: 'Wealthsimple' },
    { value: 'WEBULL', label: 'Webull US' },
    { value: 'OTHER', label: 'Other' },
  ];

  // Reset state when the modal first opens
  useEffect(() => {
    if (isOpen) {
      setAuthStep('selectBrokerage');
      setEmail('');
      setOtp('');
      setBrokerage(null);
      setOtherBrokerageName('');
      setAuthError(null);
      setLoginLink(null);
      setIsSendCodeDisabled(false);
    }
  }, [isOpen]);

  // 1) Handle brokerage selection
  const handleBrokerageSelect = (selected) => {
    setBrokerage(selected);
    setAuthError(null);

    if (selected.value === 'OTHER') {
      setAuthStep('enterBrokerageName');
    } else {
      setAuthStep('email');
    }
  };

  // 2) If user chose OTHER brokerage, ask for the name
  const handleOtherBrokerageSubmit = (e) => {
    e.preventDefault();
    setAuthStep('email'); 
  };

  // 3) Send sign-in OTP via email
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);
    setIsSendCodeDisabled(true);

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          channel: 'email',
          shouldCreateUser: true,
        },
      });
      if (error) {
        setAuthError(error.message);
        setIsSendCodeDisabled(false);
      } else {
        setAuthStep('otp');
      }
    } catch (err) {
      console.error(err);
      setAuthError('Unexpected error. Please try again.');
      setIsSendCodeDisabled(false);
    }
  };

  // 4) Verify OTP
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);
    try {
      const { error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      });
      if (error) {
        setAuthError(error.message);
      } else {
        // If user selected "OTHER", skip SnapTrade linking
        if (brokerage?.value === 'OTHER') {
          await finalizeGiveawayEntry();
        } else {
          setAuthStep('linking');
        }
      }
    } catch (err) {
      console.error(err);
      setAuthError('Unexpected error verifying code.');
    }
  };

  // 5) Linking Step – fetch SnapTrade login link
  useEffect(() => {
    const fetchAndSetLoginLink = async () => {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (user && authStep === 'linking' && brokerage?.value) {
          const link = await fetchLoginLink(user.id, brokerage.value);
          setLoginLink(link);
        }
      } catch (err) {
        setAuthError(err.message);
      }
    };
    fetchAndSetLoginLink();
  }, [authStep, brokerage]);

  // 6) Listen to SnapTrade’s message events
  useEffect(() => {
    if (authStep === 'linking') {
      const handleMessageEvent = async (e) => {
        // Ensure the origin matches SnapTrade
        if (e.origin !== 'https://app.snaptrade.com') return;
        if (typeof e.data === 'string') {
          // Example success message
          if (e.data.startsWith('SUCCESS')) {
            await finalizeGiveawayEntry();
          } else if (e.data === 'CLOSE_MODAL' || e.data === 'ABANDONED') {
            // The user closed SnapTrade's modal without linking
            setAuthError('Linking was cancelled by the user.');
          } else if (e.data.startsWith('ERROR')) {
            setAuthError(e.data);
          }
        }
      };
      window.addEventListener('message', handleMessageEvent, false);
      return () => {
        window.removeEventListener('message', handleMessageEvent, false);
      };
    }
  }, [authStep]);

  // 7) Finalizing the process
  const finalizeGiveawayEntry = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (user) {
        // Re-check if user is actually linked
        const linked = await isUserLinked(user.id);
        if (linked || brokerage?.value === 'OTHER') {
          // Mark user as participated in giveaway
          await enterGiveaway(user.id);
          onLinked();  // Callback to the parent => navigates to confirmation
        } else {
          setAuthError('Please complete linking before continuing.');
        }
      }
    } catch (err) {
      console.error(err);
      setAuthError('An error occurred. Please try again.');
    }
  };

  // If the modal is closed, do not render
  if (!isOpen) return null;

  const handleSkipLink = () => {
    // If user just wants to chat instead of linking
    onSkipLink();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-md p-6 bg-white rounded-md">
        {/* Header */}
        <div className="flex justify-between mb-4">
          <h2 className="text-xl font-semibold">
            {authStep === 'selectBrokerage' && 'Select a Platform'}
            {authStep === 'enterBrokerageName' && 'Enter Your Brokerage Name'}
            {authStep === 'email' && 'Verify Your Email'}
            {authStep === 'otp' && 'Enter Verification Code'}
            {authStep === 'linking' && 'Link Your Brokerage'}
          </h2>
          <button onClick={onClose} className="text-gray-500">✕</button>
        </div>

        {/* Error Message */}
        {authError && <div className="mb-4 text-red-500">{authError}</div>}

        {/* Brokerage Selection */}
        {authStep === 'selectBrokerage' && (
          <div>
            <p className="mb-4">Please select your brokerage:</p>
            <Select
              options={brokerageOptions}
              onChange={handleBrokerageSelect}
              placeholder="Select your brokerage..."
            />
          </div>
        )}

        {/* If 'OTHER' is chosen */}
        {authStep === 'enterBrokerageName' && (
          <form onSubmit={handleOtherBrokerageSubmit}>
            <label className="block mb-2 font-bold">Brokerage Name</label>
            <input
              type="text"
              value={otherBrokerageName}
              onChange={(e) => setOtherBrokerageName(e.target.value)}
              required
              className="w-full p-2 mb-4 border rounded"
            />
            <button type="submit" className="w-full py-2 text-white bg-blue-500 rounded">
              Continue
            </button>
          </form>
        )}

        {/* Email Submission */}
        {authStep === 'email' && (
          <form onSubmit={handleEmailSubmit}>
            <label className="block mb-2 font-bold">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isSendCodeDisabled}
              className="w-full p-2 mb-4 border rounded"
            />
            <button
              type="submit"
              className={`w-full py-2 rounded text-white ${
                isSendCodeDisabled ? 'bg-gray-400' : 'bg-blue-500'
              }`}
              disabled={isSendCodeDisabled}
            >
              {isSendCodeDisabled ? 'Code Sent' : 'Send Code'}
            </button>
          </form>
        )}

        {/* OTP Verification */}
        {authStep === 'otp' && (
          <form onSubmit={handleOtpSubmit}>
            <label className="block mb-2 font-bold">Verification Code</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              className="w-full p-2 mb-4 border rounded"
            />
            <button type="submit" className="w-full py-2 text-white bg-blue-500 rounded">
              Verify
            </button>
          </form>
        )}

        {/* Linking Step */}
        {authStep === 'linking' && (
          <div>
            <p className="mb-4">
              We’re now connecting you to SnapTrade to link your brokerage.
            </p>
            {loginLink ? (
              <>
                <iframe
                  title="SnapTrade Connection"
                  src={loginLink}
                  width="100%"
                  height="550px"
                  style={{ border: '1px solid #ccc' }}
                />
                <div className="mt-4 text-center">
                  <button
                    onClick={handleSkipLink}
                    className="text-blue-600 underline"
                  >
                    Skip Linking for Now
                  </button>
                </div>
              </>
            ) : (
              <p>Loading link ...</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthModal;
