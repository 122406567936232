// src/components/MiniSymbolOverviewWidget.js
import React, { useEffect, useRef } from 'react';

const MiniSymbolOverviewWidget = () => {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    // 1) Create the script element
    const script = document.createElement('script');
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
    script.async = true;
    
    // 2) The JSON config for your widget (symbol, styling, etc.)
    script.innerHTML = JSON.stringify({
      symbol: 'TSXV:DV',
      width: '100%',
      height: '100%',
      locale: 'en',
      dateRange: '60M',
      colorTheme: 'dark',
      isTransparent: true,
      autosize: true,
      largeChartUrl: '',
    });

    // 3) Append the script to our container <div>
    widgetContainerRef.current.appendChild(script);

    // Optional cleanup if needed
    return () => {
      // If you want to remove it on unmount:
      if (widgetContainerRef.current) {
        widgetContainerRef.current.innerHTML = '';
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container" style={{ width: '100%'}}>
      {/* The container that TradingView's script uses to render the chart */}
      <div ref={widgetContainerRef} className="tradingview-widget-container__widget" />

      {/* Optional: The TradingView link or copyright */}
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
        </a>
      </div>
    </div>
  );
};

export default MiniSymbolOverviewWidget;
