import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../NavBar';
import Footer from '../../Footer';

const OneOnOneChatPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 1) Intercom Settings
    window.intercomSettings = { app_id: 'heb10hov' }; // Replace with your real Intercom App ID

    (function () {
      var w = window; 
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', window.intercomSettings);
      } else {
        var d = document; 
        var i = function(){ i.c(arguments); };
        i.q = [];
        i.c = function(args){ i.q.push(args); };
        w.Intercom = i;

        function l(){
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/YOUR_INTERCOM_APP_ID';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s,x);
        }
        if(d.readyState === 'complete') { l(); }
        else if(w.attachEvent) { w.attachEvent('onload', l); }
        else { w.addEventListener('load', l, false); }
      }
    })();

    // 2) Auto‐open Intercom on desktop only
    const isDesktop = window.innerWidth >= 768; // or your chosen breakpoint
    if (isDesktop) {
      const timer = setTimeout(() => {
        if (window.Intercom) {
          window.Intercom('show');
          window.Intercom(
            'showNewMessage',
            'What made you skip the link account step?'
          );
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, []);

  // When user clicks "Verify Investor Status," send them to your sign up flow
  // so they can link their SnapTrade account. Then, in that flow, you can redirect
  // them to the group chat page (e.g., /giveaway/confirmation) after they’ve linked.
  const handleVerifyClick = () => {
    // Example: pass a query param instructing your sign-up flow where to redirect after linking
    navigate('/signup?next=/giveaway/confirmation'); 
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-white to-gray-100">
      <NavBar />

      <main className="flex items-center justify-center flex-1">
        <div className="grid w-full grid-cols-1 gap-8 p-8 lg:grid-cols-2 max-w-7xl">
          {/* Left Column: Headline, Paragraphs, Video */}
          <div className="flex flex-col justify-center">
            <h1 className="mb-4 text-4xl font-extrabold text-gray-800 sm:text-5xl">
              One-on-One Chat
            </h1>
            <p className="mb-6 text-lg leading-relaxed text-gray-700 sm:text-xl">
              You’re almost in the giveaway! Before finalizing your entry, we need
              a quick verification that you have a trading account. It looks like
              you skipped that step—let us know if you have any questions!
            </p>
            <p className="mb-6 text-base text-gray-600 sm:text-lg">
              Our team is here to help you one-on-one. Feel free to ask anything 
              in the chat bubble (bottom-right), or watch the video below for 
              a quick walkthrough.
            </p>

            <div className="w-full mb-6">
              <iframe
                className="w-full rounded-lg"
                height="315"
                src="https://www.youtube.com/embed/7YaTaHdAkGc?si=otb8WyIgcOzu_DnD&autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </div>

            {/* Button to continue SnapTrade linking */}
            <button
              onClick={handleVerifyClick}
              className="inline-block px-6 py-3 font-semibold text-white transition-colors bg-blue-600 rounded-lg hover:bg-blue-700"
            >
              Verify Investor Status
            </button>
          </div>

          {/* Right Column: blank on desktops so Intercom widget doesn’t overlap content */}
          <div className="hidden lg:block" />
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default OneOnOneChatPage;
