// src/routes/AppRouter.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import SignUp from '../components/SignUp';
import EnterGiveaway from '../EnterGiveaway';
import PostAuthorization from '../Pages/PostAuthorization';
import OneOnOneChatPage from '../Pages/chat/OneOnOneChatPage';
import OrderConfirmation from '../OrderConfirmation';
import CompanyInfo from '../Pages/CompanyInfo';
import Confirmation from '../Confirmation';
import GiveawayLanding from '../Pages/giveaway/GiveawayLanding';
import GiveawayConfirmation from '../Pages/giveaway/GiveawayConfirmation';
import SignupPage from '../Pages/SignUpPage';
import GroupChatPage from '../Pages/chat/GroupChatPage';
import SignInPage from '../Pages/SignInPage';
import PosthogListener from './PosthogListener';
import SignupPageBonus from '../Pages/SignUpPageBonus';

const AppRouter = () => {
  return (
    <Router>
      {/* Use a custom component to track location changes for PostHog */}
      <PosthogListener>
        <Routes>
          <Route path="/" element={<CompanyInfo />} />
          <Route path="/enter-giveaway" element={<EnterGiveaway />} />
          <Route path="/post-authorization" element={<PostAuthorization />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/signupbonus" element={<SignupPageBonus />} />
          {/* Giveaway routes */}
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/giveaway" element={<GiveawayLanding />} />
          <Route path="/giveaway/confirmation" element={<GiveawayConfirmation />} />
          <Route path="/chat/oneonone" element={<OneOnOneChatPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/group-chat" element={<GroupChatPage />} />

          {/* Add or remove routes as needed */}
        </Routes>
      </PosthogListener>
    </Router>
  );
};

export default AppRouter;
