// src/pages/SignupPageBonus.js
import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../../supabaseClient';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AuthContext } from '../InfoComponents/AuthProvider';
import NavBar from '../NavBar';

const SignupPageBonus = () => {
  const [authStep, setAuthStep] = useState('email'); 
  const [selectedBrokerage, setSelectedBrokerage] = useState(null);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [authError, setAuthError] = useState(null);
  const [loginLink, setLoginLink] = useState(null);
  const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(false);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const brokerageOptions = [
    { value: 'ETRADE', label: 'E*TRADE' },
    { value: 'ROBINHOOD', label: 'Robinhood' },
    { value: 'SCHWAB', label: 'Schwab' },
    { value: 'TRADESTATION', label: 'TradeStation' },
    { value: 'VANGUARD', label: 'Vanguard US' },
    { value: 'WEALTHSIMPLETRADE', label: 'Wealthsimple' },
    { value: 'WEBULL', label: 'Webull US' },
  ];

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);
    setIsSendCodeDisabled(true);

    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          channel: 'email', 
          shouldCreateUser: true,
        },
      });

      if (error) {
        setAuthError(error.message);
        setIsSendCodeDisabled(false);
      } else {
        setAuthStep('otp');
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
      setIsSendCodeDisabled(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);

    try {
      const { error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      });

      if (error) {
        setAuthError(error.message);
      } else {
        // Authentication successful
        setAuthStep('selectBrokerage'); 
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
    }
  };

  const handleBrokerageSelect = (selectedOption) => {
    setSelectedBrokerage(selectedOption);
    setAuthError(null);
    setLoginLink(null);
    setAuthStep('linking'); 
  };

  useEffect(() => {
    const postAuthProcess = async () => {
      if (user) {
        localStorage.setItem('supabaseUserId', user.id);

        const isLinked = await checkIfUserIsLinked(user.id);
        if (isLinked) {
          // If they’re already linked, jump straight to group chat
          navigate('/group-chat');
        } else if (selectedBrokerage) {
          setAuthStep('linking');
        } else {
          setAuthStep('selectBrokerage');
        }
      }
    };

    postAuthProcess();
  }, [user, selectedBrokerage, navigate]);

  const checkIfUserIsLinked = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendUrl}/is-linked`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
      });
      if (response.ok) {
        const data = await response.json();
        return data.isLinked;
      } else {
        console.error('Failed to check if user is linked');
        return false;
      }
    } catch (error) {
      console.error('Error during checkIfUserIsLinked:', error);
      return false;
    }
  };

  const fetchLoginLink = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendUrl}/link-snaptrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
        body: JSON.stringify({
          snaptradeUserId: `snaptrade-${supabaseUserId}`,
          brokerage: selectedBrokerage.value,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setLoginLink(data.redirectURI);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to get SnapTrade login link');
      }
    } catch (error) {
      console.error('Error during fetching login link:', error);
      setAuthError(error.message);
      setAuthStep('error');
    }
  };

  useEffect(() => {
    const proceedToLinking = async () => {
      if (authStep === 'linking' && user && selectedBrokerage && !loginLink) {
        await fetchLoginLink(user.id);
      }
    };
    proceedToLinking();
  }, [authStep, user, selectedBrokerage, loginLink]);

  useEffect(() => {
    if (authStep === 'linking' && user && selectedBrokerage) {
      const handleMessageEvent = async (e) => {
        if (e.origin !== 'https://app.snaptrade.com') return;
        if (typeof e.data === 'string') {
          if (e.data.startsWith('SUCCESS')) {
            console.log('SnapTrade linking successful');
            const isLinked = await checkIfUserIsLinked(user.id);
            if (isLinked) {
              await enterGiveaway(user.id);
              // Redirect to group chat
              navigate('/group-chat');
            } else {
              setAuthError('Please complete linking your brokerage accounts.');
            }
          } else if (
            e.data === 'CLOSE_MODAL' ||
            e.data === 'ABANDONED' ||
            e.data === 'CLOSED'
          ) {
            console.log('User closed the SnapTrade linking modal');
            setAuthError('SnapTrade linking was cancelled.');
            setAuthStep('selectBrokerage');
            setLoginLink(null);
          } else if (e.data.startsWith('ERROR')) {
            console.error('Error during SnapTrade linking:', e.data);
            setAuthError(`Error: ${e.data}`);
          }
        }
      };

      window.addEventListener('message', handleMessageEvent, false);
      return () => {
        window.removeEventListener('message', handleMessageEvent, false);
      };
    }
  }, [authStep, user, selectedBrokerage, navigate]);

  const enterGiveaway = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendUrl}/enter-giveaway`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
      });
      if (!response.ok) {
        console.error('Failed to enter giveaway');
      }
    } catch (error) {
      console.error('Error entering giveaway:', error.message);
    }
  };

  return (
    <div>
      <NavBar />
      <div className="flex flex-col h-screen md:flex-row signup-container">
        <div className="flex items-center justify-center flex-1 p-4 text-white bg-black md:items-start md:justify-start md:p-12">
          <div className="text-center md:text-left">
            <h1 className="mb-4 text-2xl font-bold md:text-4xl">
              Finish Setting Up Your Account
            </h1>
            <p className="text-base md:text-lg">
              Sign up to be able to securely buy stock anywhere you do your research with your existing trading account.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center flex-1 p-4 right-pane md:p-0">
          <div className="w-full max-w-md p-6">
            <div className="mb-4 text-center md:text-left">
              <h2 className="text-xl font-semibold md:text-2xl">
                {authStep === 'email' && 'Verify Email to Continue'}
                {authStep === 'otp' && 'Enter Verification Code'}
                {authStep === 'selectBrokerage' && 'Select a Platform'}
                {authStep === 'linking' && 'Sign In to Your Brokerage'}
                {authStep === 'error' && 'Something Went Wrong'}
              </h2>
            </div>

            {authError && (
              <div className="mb-4 text-center text-red-500 md:text-left">
                {authError}
              </div>
            )}

            {authStep === 'email' && (
              <form onSubmit={handleEmailSubmit} className="text-center md:text-left">
                <label className="block mb-2 font-bold">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isSendCodeDisabled}
                  className="w-full p-2 mb-4 border border-black rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className={`w-full py-2 text-white rounded-md ${
                    isSendCodeDisabled
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-black hover:bg-blue-400 hover:text-black hover:border-black'
                  }`}
                  disabled={isSendCodeDisabled}
                >
                  {isSendCodeDisabled ? 'Code Sent' : 'Send Code'}
                </button>
              </form>
            )}

            {authStep === 'otp' && (
              <form onSubmit={handleOtpSubmit} className="text-center md:text-left">
                <label className="block mb-2 font-bold">Verification Code</label>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                  className="w-full p-2 mb-4 border border-black rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="w-full py-2 text-white bg-black rounded-md hover:bg-blue-300"
                >
                  Verify Code
                </button>
              </form>
            )}

            {authStep === 'selectBrokerage' && (
              <div className="text-center md:text-left">
                <p className="mb-4">Please select your brokerage to continue:</p>
                <Select
                  options={brokerageOptions}
                  onChange={handleBrokerageSelect}
                  placeholder="Select your brokerage..."
                  isSearchable
                />
              </div>
            )}

            {authStep === 'linking' && (
              <div className="text-center md:text-left">
                {loginLink ? (
                  <iframe
                    id="snaptrade-connection-portal"
                    src={loginLink}
                    title="SnapTrade Connection Portal"
                    className="w-full h-96 md:h-[600px]"
                    allowFullScreen
                  />
                ) : (
                  <p>Loading trading account linking...</p>
                )}
              </div>
            )}

            {authStep === 'error' && (
              <p className="text-red-500">
                An unexpected error occurred. Please try again later.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPageBonus;
