// src/App.js
import React, { useEffect } from 'react';
import posthog from 'posthog-js';
import { AuthProvider } from './components/InfoComponents/AuthProvider';
import AppRouter from './components/routes/AppRouter';

const App = () => {
  useEffect(() => {
    // Initialize PostHog once on app load
    posthog.init('phc_4PDuS8yZSZrnkKJ1KZmAvTVdNlpZHPk5CGpOypwDtC6', {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always',
    });
  }, []);

  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
};

export default App;
