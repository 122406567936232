// src/pages/GroupChatPage.js

import React, {
    useEffect,
    useState,
    useRef,
    useContext,
    useCallback
  } from 'react';
  import { supabase } from '../../../supabaseClient';
  import NavBar from '../../NavBar';
  import useCompanyInfo from '../../../hooks/useCompanyInfo';
  import useSnaptradeAccounts from '../../../hooks/useSnaptradeAccounts';
  import { placeOrder } from '../../../services/snaptradeService';
  import { AuthContext } from '../../InfoComponents/AuthProvider';
  import RightSidebar from '../RightSidebar';
  
  // For mobile, we can reuse the same BuyCardPostAuth at the top of the chat column:
  import BuyCardPostAuth from '../../InfoComponents/BuyCardPostAuth';
  
  let renderCount = 0;
  
  const GroupChatPage = () => {
    renderCount += 1;
    console.log(`GroupChatPage render #${renderCount}`);
  
    // ─────────────────────────────────────────────
    // 1) Chat States & Refs
    // ─────────────────────────────────────────────
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [username, setUsername] = useState('');
    const [isEditingName, setIsEditingName] = useState(false);
    const [tempName, setTempName] = useState('');
    const messagesEndRef = useRef(null);
  
    // Giveaway progress
    const totalEntries = 726;
    const goalEntries = 1000;
    const progressPercentage = Math.round((totalEntries / goalEntries) * 100);
  
    // ─────────────────────────────────────────────
    // 2) Company Data + SnapTrade
    // ─────────────────────────────────────────────
    const { company, stats, marketPrice, error, loading } = useCompanyInfo();
    console.log('company =', company);
    console.log('error =', error);
  
    const { user } = useContext(AuthContext);
    const userId = user?.id || null;
    console.log('AuthContext userId =', userId);
  
    const { accounts } = useSnaptradeAccounts(userId);
    console.log('accounts =', accounts);
  
    // ─────────────────────────────────────────────
    // 3) Buy States
    // ─────────────────────────────────────────────
    const [shares, setShares] = useState('');
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [orderConfirmed, setOrderConfirmed] = useState(false);
    const [orderData, setOrderData] = useState(null);
    const [disableInteractions, setDisableInteractions] = useState(false);
    const [companyName, setCompanyName] = useState('');
  
    useEffect(() => {
      const supabaseUserId = localStorage.getItem('supabaseUserId');
      console.log('useEffect[] -> supabaseUserId from localStorage:', supabaseUserId);
    }, []);
  
    // ─────────────────────────────────────────────
    // 4) Chat Initialization & Subscription
    // ─────────────────────────────────────────────
    const messageChannelRef = useRef(null);
  
    useEffect(() => {
      console.log('useEffect -> Chat Initialization ([])');
  
      // Retrieve username from localStorage
      const storedName = localStorage.getItem('chatUsername');
      if (storedName) {
        console.log('Restoring username from localStorage:', storedName);
        setUsername(storedName);
      }
  
      // Fetch initial messages
      fetchMessages();
  
      // Subscribe to new messages
      console.log('Subscribing to messages channel...');
      messageChannelRef.current = supabase
        .channel('public:messages')
        .on(
          'postgres_changes',
          { event: 'INSERT', schema: 'public', table: 'messages' },
          (payload) => {
            const newMsg = payload.new || payload.record;
            setMessages((prev) => [...prev, newMsg]);
          }
        )
        .subscribe((status) => {
          console.log('Channel status =', status);
          if (status === 'SUBSCRIBED') {
            console.log('Subscribed to messages channel');
          }
        });
  
      return () => {
        console.log('Cleaning up message channel subscription...');
        if (messageChannelRef.current) {
          supabase.removeChannel(messageChannelRef.current);
        }
      };
      // eslint-disable-next-line
    }, []);
  
    // Keep scroll at bottom of chat
    useEffect(() => {
      console.log('useEffect -> messages changed, scroll to bottom...');
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);
  
    // ─────────────────────────────────────────────
    // 5) Fetch Chat Messages
    // ─────────────────────────────────────────────
    const fetchMessages = useCallback(async () => {
      console.log('fetchMessages() called...');
      const { data, error } = await supabase
        .from('messages')
        .select('*')
        .order('created_at', { ascending: true })
        .limit(100);
  
      if (error) {
        console.error('Error fetching messages:', error);
        return;
      }
      console.log('Messages fetched, count =', data.length);
      setMessages(data);
    }, []);
  
    // ─────────────────────────────────────────────
    // 6) Handle Send Message
    // ─────────────────────────────────────────────
    const handleSendMessage = async (e) => {
      e.preventDefault();
      if (!newMessage.trim()) return;
      const nameToUse = username || 'Anonymous';
  
      console.log('Inserting new message:', newMessage);
      const { error } = await supabase.from('messages').insert([
        {
          content: newMessage,
          username: nameToUse,
        },
      ]);
      if (!error) {
        console.log('Message inserted successfully');
        setNewMessage('');
      } else {
        console.error('Error sending message:', error);
      }
    };
  
    // ─────────────────────────────────────────────
    // 7) Username Editing
    // ─────────────────────────────────────────────
    const handleEditName = () => {
      setTempName(username);
      setIsEditingName(true);
    };
  
    
const handleSaveName = async () => {
    const trimmed = tempName.trim() || 'Anonymous';
  
    // 1) If no user is logged in, or no user ID in local storage, bail out
    const supabaseUserId = localStorage.getItem('supabaseUserId');
    if (!supabaseUserId) {
      alert('You must be signed in to set a username.');
      return;
    }
  
    try {
      // 2) POST to /set-username
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${backendUrl}/set-username`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
        body: JSON.stringify({ username: trimmed }),
      });
  
      if (!response.ok) {
        // If it's 403, that means username is already set, or some error
        const errorData = await response.json();
        alert(`Failed to set username: ${errorData.error || 'Unknown error'}`);
        return;
      }
  
      // 3) If success, update local state and localStorage
      setUsername(trimmed);
      localStorage.setItem('chatUsername', trimmed);
      setIsEditingName(false);
      alert('Username saved successfully in the database!');
    } catch (err) {
      console.error('Error setting username:', err);
      alert(`Error: ${err.message}`);
    }
  };
  
    const handleCancelEdit = () => {
      setIsEditingName(false);
    };
  
    // ─────────────────────────────────────────────
    // 8) Place Order (Buy Stock)
    // ─────────────────────────────────────────────
    const handleBuyStock = async () => {
      console.log('handleBuyStock() called, userId=', userId);
      if (!userId) {
        alert('Please sign in first!');
        return;
      }
      if (!selectedAccount || !shares) {
        alert('Select an account and shares first!');
        return;
      }
      if (!company?.ticker_symbol_ca) {
        alert('No company data or missing ticker symbol!');
        return;
      }
  
      setDisableInteractions(true);
      try {
        const symbol = company.ticker_symbol_ca;
        const quantity = parseFloat(shares);
  
        console.log('Placing order for symbol:', symbol, 'quantity:', quantity);
        const data = await placeOrder({
          userId,
          accountId: selectedAccount,
          symbol,
          quantity,
          marketPrice,
        });
  
        console.log('Order placed, data =', data);
        setOrderData({
          ...data,
          accountName: accounts.find((a) => a.id === selectedAccount)?.name || '',
          symbol,
          quantity,
          marketPrice,
          estimatedCost: (quantity * parseFloat(marketPrice)).toFixed(2),
          companyName: company.name,
        });
        setCompanyName(company.name);
        setOrderConfirmed(true);
      } catch (err) {
        alert(`Error placing order: ${err.message}`);
      } finally {
        setDisableInteractions(false);
      }
    };
  
    // ─────────────────────────────────────────────
    // 9) Render
    // ─────────────────────────────────────────────
    console.log('Rendering GroupChatPage -> returning JSX...');
  
    return (
      <div className="flex flex-col h-screen">
        <NavBar />
  
        <div className="flex flex-1 overflow-hidden">
          {/* LEFT SIDEBAR (Giveaway Info) - Only on md+ */}
          <aside className="flex-col flex-shrink-0 hidden w-64 p-4 text-white bg-gray-800 md:flex">
            {/* Giveaway Info */}
            <div className="mb-6">
              <h2 className="text-2xl font-bold">Silver Bar Giveaway</h2>
              <p className="mt-2 text-sm text-gray-300">
                Join the conversation and keep an eye on your next big investment opportunity.
              </p>
            </div>
  
            {/* Progress */}
            <div className="mb-6">
              <p className="text-sm font-medium">
                Entries: {totalEntries} / {goalEntries}
              </p>
              <div className="w-full h-2 mt-2 bg-gray-700 rounded">
                <div
                  className="h-2 bg-blue-400 rounded"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
              <p className="mt-1 text-xs text-gray-400">
                {progressPercentage}% filled
              </p>
            </div>
  
            {/* Username Edit */}
            <div className="pt-4 mt-auto border-t border-gray-700">
              <div className="flex items-center justify-between">
                <span className="font-semibold">Username:</span>
                <span className="text-blue-300">{username || 'Anonymous'}</span>
              </div>
              <button
                onClick={handleEditName}
                className="w-full py-2 mt-2 text-sm text-center bg-gray-700 rounded hover:bg-gray-600"
              >
                Edit Username
              </button>
            </div>
          </aside>
  
          {/* MAIN CHAT COLUMN */}
          <div className="flex flex-col flex-1 bg-white border-r border-gray-200">
            {/* 
              MOBILE BUY CARD (above the chat):
              This block is only visible on small screens (block on mobile, hidden on md+).
            */}
            {company && (
              <div className="block p-4 border-b border-gray-200 md:hidden">
                <h2 className="mb-2 text-xl font-bold">
                  Dolly Varden Silver Chat
                </h2>
                {/* On mobile, show BuyCard at top */}
                <BuyCardPostAuth
                  company={company}
                  shares={shares}
                  handleSharesChange={(e) => setShares(e.target.value)}
                  marketPrice={marketPrice}
                  estimatedCost={(Number(shares) * Number(marketPrice)).toFixed(2)}
                  disableInteractions={disableInteractions}
                  accounts={accounts}
                  selectedAccount={selectedAccount}
                  handleSelectAccount={setSelectedAccount}
                  handleBuyStock={handleBuyStock}
                  orderConfirmed={orderConfirmed}
                  orderData={orderData}
                  setOrderConfirmed={setOrderConfirmed}
                  setOrderData={setOrderData}
                  setShares={setShares}
                  setSelectedAccount={setSelectedAccount}
                  setCompanyName={setCompanyName}
                  setMarketPrice={() => {}}
                />
              </div>
            )}
  
            {/* Messages List */}
            <div className="flex-1 p-4 space-y-2 overflow-y-auto">
              {messages.map((msg) => {
                const isOwn = msg.username === (username || 'Anonymous');
                return (
                  <div key={msg.id} className="flex items-start">
                    {!isOwn && (
                      <div className="mr-2 font-semibold text-gray-700 text-sm min-w-[5rem] text-right">
                        {msg.username}:
                      </div>
                    )}
                    <div
                      className={`relative px-3 py-2 rounded-lg max-w-xl ${
                        isOwn
                          ? 'ml-auto bg-blue-50 border border-blue-200 text-blue-900'
                          : 'bg-gray-100 text-gray-800'
                      }`}
                    >
                      {isOwn && (
                        <div className="absolute top-0 right-0 p-1 text-xs text-gray-400">
                          You
                        </div>
                      )}
                      <p className="leading-relaxed break-words">
                        {msg.content}
                      </p>
                      <div className="mt-1 text-xs text-right text-gray-500">
                        {new Date(msg.created_at).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
  
            {/* Message Input */}
            <div className="p-4 border-t border-gray-200">
              <form onSubmit={handleSendMessage} className="flex space-x-2">
                <input
                  type="text"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1 p-2 border rounded focus:outline-none"
                />
                <button
                  type="submit"
                  disabled={!newMessage.trim()}
                  className={`px-4 py-2 rounded text-white font-semibold ${
                    newMessage.trim()
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-gray-400 cursor-not-allowed'
                  }`}
                >
                  Send
                </button>
              </form>
            </div>
          </div>
  
          {/* RIGHT SIDEBAR (Desktop only) */}
          <RightSidebar
            loading={loading}
            error={error}
            company={company}
            stats={stats}
            marketPrice={marketPrice}
            shares={shares}
            setShares={setShares}
            disableInteractions={disableInteractions}
            accounts={accounts}
            selectedAccount={selectedAccount}
            handleSelectAccount={setSelectedAccount}
            handleBuyStock={handleBuyStock}
            orderConfirmed={orderConfirmed}
            orderData={orderData}
            setOrderConfirmed={setOrderConfirmed}
            setOrderData={setOrderData}
            setCompanyName={setCompanyName}
            setSelectedAccount={setSelectedAccount}
          />
        </div>
  
        {/* EDIT NAME MODAL */}
        {isEditingName && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="p-6 bg-white rounded shadow w-80">
              <h3 className="mb-4 text-xl font-bold">Edit Username</h3>
              <input
                type="text"
                value={tempName}
                onChange={(e) => setTempName(e.target.value)}
                className="w-full p-2 mb-4 border rounded focus:outline-none"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={handleCancelEdit}
                  className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveName}
                  className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default GroupChatPage;
  