// src/pages/SignInPage.js

import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import NavBar from '../NavBar';
import { useNavigate } from 'react-router-dom';

const SignInPage = () => {
  const [authStep, setAuthStep] = useState('email');  // "email" -> "otp" -> "done"
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [authError, setAuthError] = useState(null);
  const [isSendCodeDisabled, setIsSendCodeDisabled] = useState(false);

  const navigate = useNavigate();

  // 1) Handle Email Submission
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);
    setIsSendCodeDisabled(true);

    try {
      // Sign in with OTP (will fail if user doesn't exist, unless shouldCreateUser = true)
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          channel: 'email',
          shouldCreateUser: false,  // For sign-in only (no new user creation).
        },
      });

      if (error) {
        setAuthError(error.message);
        setIsSendCodeDisabled(false);
      } else {
        setAuthStep('otp');
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
      setIsSendCodeDisabled(false);
    }
  };

  // 2) Handle OTP Submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setAuthError(null);

    try {
      const { error } = await supabase.auth.verifyOtp({
        email,
        token: otp,
        type: 'email',
      });

      if (error) {
        setAuthError(error.message);
      } else {
        // Sign-in successful
        setAuthStep('done');
      }
    } catch (err) {
      setAuthError('An unexpected error occurred. Please try again.');
      console.error(err);
    }
  };

  // 3) If user is done, we can redirect or show a success message
  const handleContinue = () => {
    // For example, go to a group chat, dashboard, etc.
    navigate('/group-chat');
  };

  return (
    <div>
      <NavBar />
      <div className="flex flex-col h-screen md:flex-row signup-container">
        {/* Left Section (Dark) for Branding */}
        <div className="flex items-center justify-center flex-1 p-4 text-white bg-black md:items-start md:justify-start md:p-12">
          <div className="text-center md:text-left">
            <h1 className="mb-4 text-2xl font-bold md:text-4xl">
              Welcome Back
            </h1>
            <p className="text-base md:text-lg">
              Sign in to access your account and join our giveaways.
            </p>
          </div>
        </div>

        {/* Right Section (Form) */}
        <div className="flex items-center justify-center flex-1 p-4 right-pane md:p-0">
          <div className="w-full max-w-md p-6">
            {/* Heading */}
            <div className="mb-4 text-center md:text-left">
              <h2 className="text-xl font-semibold md:text-2xl">
                {authStep === 'email' && 'Sign In with Your Email'}
                {authStep === 'otp' && 'Enter Your Verification Code'}
                {authStep === 'done' && 'Sign In Successful'}
              </h2>
            </div>

            {/* Error */}
            {authError && (
              <div className="mb-4 text-center text-red-500 md:text-left">
                {authError}
              </div>
            )}

            {/* Step: Email */}
            {authStep === 'email' && (
              <form onSubmit={handleEmailSubmit} className="text-center md:text-left">
                <label className="block mb-2 font-bold">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isSendCodeDisabled}
                  className="w-full p-2 mb-4 border border-black rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className={`w-full py-2 text-white rounded-md ${
                    isSendCodeDisabled
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-black hover:bg-blue-400 hover:text-black hover:border-black'
                  }`}
                  disabled={isSendCodeDisabled}
                >
                  {isSendCodeDisabled ? 'Code Sent' : 'Send Code'}
                </button>
              </form>
            )}

            {/* Step: OTP */}
            {authStep === 'otp' && (
              <form onSubmit={handleOtpSubmit} className="text-center md:text-left">
                <label className="block mb-2 font-bold">Verification Code</label>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                  className="w-full p-2 mb-4 border border-black rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="w-full py-2 text-white bg-black rounded-md hover:bg-blue-300"
                >
                  Verify Code
                </button>
              </form>
            )}

            {/* Step: Done */}
            {authStep === 'done' && (
              <div className="text-center md:text-left">
                <p className="mb-4">You're signed in! Welcome back.</p>
                <button
                  onClick={handleContinue}
                  className="w-full px-4 py-2 text-white bg-black rounded-md hover:bg-blue-300"
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
