import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthModal from '../../InfoComponents/AuthModal';
import Footer from '../../Footer';
import NavBar from '../../NavBar';
import { AuthContext } from '../../InfoComponents/AuthProvider';

const GiveawayLanding = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [totalEntries, setTotalEntries] = useState(691);
  const [highlight, setHighlight] = useState(false);
  const goalEntries = 1000;
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isUserLinked, setIsUserLinked] = useState(false);

  useEffect(() => {
    const checkLinkStatus = async () => {
      if (user) {
        const linked = await checkIfUserIsLinked(user.id);
        setIsUserLinked(linked);
      } else {
        setIsUserLinked(false);
      }
    };

    checkLinkStatus();
  }, [user]);

  const handleParticipateClick = () => {
    if (!user || !isUserLinked) {
      // Open AuthModal to prompt for authentication and/or linking
      setIsAuthModalOpen(true);
    } else {
      // User is authenticated and linked, proceed to confirmation
      navigate('/giveaway/confirmation');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalEntries((prevEntries) => {
        if (prevEntries < goalEntries) {
          setHighlight(true);
          setTimeout(() => setHighlight(false), 1000); // Highlight for 1 second
          return prevEntries + 1;
        }
        return prevEntries;
      });
    }, Math.floor(Math.random() * 8000) + 2000); // Random interval between 2-10 seconds

    return () => clearInterval(interval);
  }, []);

  const progressPercentage = (totalEntries / goalEntries) * 100;

  // Function to check if the user has linked their SnapTrade account
  const checkIfUserIsLinked = async (supabaseUserId) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;

      const response = await fetch(`${backendUrl}/is-linked`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Supabase-User-ID': supabaseUserId,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Is user linked:', data.isLinked);
        return data.isLinked; // Expects { isLinked: true/false }
      } else {
        console.error('Failed to check if user is linked');
        return false;
      }
    } catch (error) {
      console.error('Error during checkIfUserIsLinked:', error);
      return false;
    }
  };

  return (
    <div className="flex flex-col justify-between min-h-screen text-black">
      <NavBar />
      {/* Main Content */}
      <div className="flex items-center justify-center flex-1 p-6">
        <div className="flex flex-col items-center justify-center w-full p-8 space-y-8 text-black max-w-7xl lg:flex-row lg:space-y-0 lg:space-x-12">
          {/* Text Section */}
          <div className="flex flex-col items-center w-full space-y-4 text-center lg:w-1/3 lg:items-start lg:text-left lg:order-2 lg:pl-12">
            <h1 className="text-4xl font-semibold text-center">
              We're giving away a 10 oz. Silver Bar!!!
            </h1>

            {/* Text below the button */}
            <p className="text-lg text-center">
              Enter for FREE today before it's full!
            </p>
            {/* Button */}
            <button
              className="self-center px-6 py-2 mt-6 font-bold text-center text-white bg-black border-2 border-black rounded-md hover:bg-white hover:text-black"
              onClick={handleParticipateClick}
            >
              Enter Giveaway Now
            </button>
            {/* Progress Bar Section */}
            <div className="w-full mt-4">
              <div
                className={`mb-4 text-lg font-medium text-center text-gray-700 transition-transform duration-500 ${
                  highlight ? 'scale-110 text-blue-600' : ''
                }`}
              >
                Total Entries: {totalEntries} / {goalEntries}
              </div>
              <div className="w-full h-4 mb-6 bg-gray-200 rounded-full">
                <div
                  className="h-4 transition-all duration-500 bg-blue-600 rounded-full"
                  style={{ width: `${progressPercentage}%` }}
                ></div>
              </div>
            </div>
          </div>

          {/* YouTube Video Section */}
          <div className="w-full lg:w-3/4 lg:order-1">
            <iframe
              width="100%"
              height="450"
              src="https://www.youtube.com/embed/HEjjK7YIOyg?si=SAgZQfAwvPDsg-2V?autoplay=1&mute=0"
              title="Giveaway Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full rounded-md shadow-lg"
            ></iframe>
          </div>
        </div>
      </div>

      {/* AuthModal Component */}
      <AuthModal
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
        onAuthSuccess={async () => {
          setIsAuthModalOpen(false);
          // Re-check if the user has linked their account after auth success
          if (user) {
            const linked = await checkIfUserIsLinked(user.id);
            if (linked) {
              navigate('/giveaway/confirmation');
            } else {
              // Open AuthModal again to prompt linking
              setIsAuthModalOpen(true);
            }
          }
        }}
        redirectTo="/giveaway/confirmation"
        onSkipLink={() => navigate('/chat/oneonone')} 
      />

      <Footer />
    </div>
  );
};

export default GiveawayLanding;
