// GiveawayConfirmation.js

import React, { useEffect, useState, useRef } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { supabase } from '../../../supabaseClient';
import NavBar from '../../NavBar';

const GiveawayConfirmation = () => {
  const totalEntries = 726;
  const goalEntries = 1000;
  const progressPercentage = (totalEntries / goalEntries) * 100;

  // Chat state
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [username, setUsername] = useState('');
  const messagesEndRef = useRef(null);

  // Fetch initial messages and subscribe to new messages
  useEffect(() => {
    fetchMessages();

    // Create a channel for real-time subscriptions
    const channel = supabase
      .channel('public:messages')
      .on(
        'postgres_changes',
        { event: 'INSERT', schema: 'public', table: 'messages' },
        (payload) => {
          const newMessage = payload.new || payload.record;
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Subscribed to messages channel');
        }
      });

    return () => {
      // Unsubscribe from the channel when the component unmounts
      supabase.removeChannel(channel);
    };
  }, []);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const fetchMessages = async () => {
    const { data, error } = await supabase
      .from('messages')
      .select('*')
      .order('created_at', { ascending: true })
      .limit(100); // Fetch latest 100 messages

    if (error) {
      console.error('Error fetching messages:', error);
    } else {
      setMessages(data);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (newMessage.trim() === '') return;

    const { error } = await supabase.from('messages').insert([
      {
        content: newMessage,
        username: username || 'Anonymous',
      },
    ]);

    if (error) {
      console.error('Error sending message:', error);
    } else {
      setNewMessage('');
    }
  };

  const handleLearnMoreClick = () => {
    window.open('https://dv.orbiton.app/', '_blank');
  };

  return (
    <div>
      <NavBar />
      <div className="container flex flex-col p-6 mx-auto md:flex-row">
        {/* Left side: Confirmation message */}
        <div className="p-6 md:w-1/2">
          <div>
            <div className="flex justify-center mb-4">
              <CheckCircleIcon className="w-16 h-16 text-green-500" />
            </div>
            <h1 className="mb-4 text-3xl font-bold text-center text-gray-800">
              Thank You for Participating!
            </h1>
            <p className="mb-6 text-center text-gray-600">
              Your entry has been received. We will announce the winners soon. Good luck!
            </p>

            <div className="mb-4 text-lg font-medium text-center text-gray-700">
              Total Entries: {totalEntries} / {goalEntries}
            </div>

            <div className="w-full h-4 mb-6 bg-gray-200 rounded-full">
              <div
                className="h-4 transition-all duration-500 bg-blue-600 rounded-full"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>

            {/* Call-to-Action Button */}
            <button
              onClick={handleLearnMoreClick}
              className="w-full px-4 py-3 mb-8 font-bold text-white transition duration-300 bg-blue-600 rounded hover:bg-blue-700"
            >
              Interested in Silver Mining Companies?
            </button>
          </div>
        </div>

        {/* Right side: Chat */}
        <div className="p-6 md:w-1/2">
          <div>
            <h2 className="mb-4 text-xl font-semibold text-gray-800">Community Chat</h2>

            {/* Messages Display */}
            <div className="p-4 mb-4 overflow-y-auto bg-gray-100 rounded-lg h-96">
              {messages.map((message) => {
                const isOwnMessage = message.username === (username || 'Anonymous');
                return (
                  <div
                    key={message.id}
                    className={`mb-2 flex ${
                      isOwnMessage ? 'justify-end' : 'justify-start'
                    }`}
                  >
                    <div
                      className={`max-w-xs px-4 py-2 rounded-lg ${
                        isOwnMessage ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                      }`}
                    >
                      {!isOwnMessage && (
                        <div className="text-sm font-semibold">{message.username}</div>
                      )}
                      <div>{message.content}</div>
                      <div className="text-xs text-right text-white">
                        {new Date(message.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div ref={messagesEndRef}></div>
            </div>

            {/* Message Input */}
            <form onSubmit={handleSendMessage} className="flex flex-col">
              {username === '' && (
                <input
                  type="text"
                  placeholder="Your name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full p-2 mb-2 border rounded"
                  required
                />
              )}
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-1 p-2 mb-2 border rounded"
                />
                <button
                  type="submit"
                  disabled={newMessage.trim() === ''}
                  className={`ml-2 mb-2 px-4 py-2 rounded ${
                    newMessage.trim() === ''
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  } text-white font-bold transition duration-300`}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiveawayConfirmation;
